<template>
    <div>
        <HeaderSec />
        <div class="ModalP">
            <div class="textos">
                <h1>Error 404</h1>
                <p>Parece que algo salió mal. Lamentamos las molestias.</p>
                <a href="/laason">Regresar a Inicio</a>
            </div>
            
        </div>
    </div>
</template>

<script>


import HeaderSec from '../HeaderSec.vue';

export default {
    name: 'ErrorLaason',

    components:{
        HeaderSec
    },

}
</script>

<style scoped>

.ModalP{
    height: 43vw;
    background-image: url('../../assets/ErrorB.png');
    background-repeat: no-repeat;
    background-size: 44.375VW;
    background-position: bottom;
    background-position-x: 31vw;
}
.textos {
    padding-top: 8.385416666666666VW;
    padding-left: 26.406249999999996VW;
}
.textos h1 {
    color: #6FBA91;
    font-size: 2.864583333333333VW;
    font-weight: 900;
}
.textos p {
    color: #6FBA91;
    font-size: 0.78125VW;
    font-weight: 400;
    margin-bottom: 2.2395833333333335VW;
}
.textos a {
    color: white;
    background-color: #0A4CA1;
    font-size: 0.6770833333333334VW;
    font-weight: 900;
    padding: 1.09375VW 2.5VW;
    border-radius: 5vw;
}

@media (max-width: 768px) {

.ModalP{
    height: 190vw;
    background-image: url('../../assets/ErrorBP.png');
    background-repeat: no-repeat;
    background-size: 99.375VW;
    background-position: bottom;
    background-position-x: 0vw;
}
.textos {
    padding-top: 8.385416666666666VW;
    padding-left: 22.40625VW;
}
.textos h1 {
    font-size: 13.285024154589372VW;
}
.textos p {
    width: 42vw;
    font-size: 3.6231884057971016VW;
    margin-bottom: 11.83574879227053VW;
}
.textos a {
    font-size: 3.140096618357488VW;
    font-weight: 900;
    padding: 5.072463768115942VW 11.594202898550725VW;
    border-radius: 10vw;
}


}

</style>
